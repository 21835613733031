<template>
    <div class="main">
        <div class="give">
            <div class="give-title">
                <div class="title1">体验赠送</div>
                <div class="title2">说明：初次入驻的回收商家的体验次数赠送设置</div>
                <div class="title3" @click="openDialog(5, init_give)">变更记录</div>
            </div>
            <div class="give-num">
                <div>赠送次数</div>
                <div class="num">
                    <div v-if="!init_give.show">{{init_give.ruleValue}} </div>
                    <el-input v-else v-model="init_give.ruleValue" autofocus @blur="validRule('num', 'init_give')" placeholder="请输入赠送次数"></el-input>
                    <span> 次</span> 
                </div>
                <el-button size="small" type="primary" v-if="!init_give.show" @click="edit('init_give')" >编辑</el-button>
                <el-button size="small" type="primary" v-else @click="openDialog(1, init_give)">保存</el-button>
                <!-- <div class="btn" v-if="!init_give.show" @click="edit('init_give')">编辑</div>
                <div class="btn" v-else @click="openDialog(1, init_give)">保存</div> -->
            </div>
        </div>
        <div class="transaction">
            <div class="give-title">
                <div class="title1">交易扣费</div>
                <div class="title2">说明：按回收商家每笔成交订单（收货成功订单）扣除对应次次数</div>
                <div class="title3" @click="openDialog(5, warning_threshold)">变更记录</div>
            </div>
            <div class="flex">
                <div class="give-num">
                    <div>收货时扣除</div>
                    <div class="num">
                        <div v-if="!receiving_consumption.show">{{receiving_consumption.ruleValue}} </div>
                        <el-input v-else v-model="receiving_consumption.ruleValue" autofocus placeholder="请输入赠送次数"></el-input>
                        <span> 次</span> 
                    </div>
                    <!-- <div class="btn" v-if="!receiving_consumption.show" @click="receiving_consumption.show = true">编辑</div>
                    <div class="btn" v-else @click="openDialog(1, receiving_consumption)">保存</div> -->
                </div>
                <div class="give-num">
                    <div>预警阈值</div>
                    <div class="num">
                        <div v-if="!warning_threshold.show">{{warning_threshold.ruleValue}} </div>
                        <el-input v-else v-model="warning_threshold.ruleValue" autofocus @blur="validRule('num', 'warning_threshold')" placeholder="请输入预警阈值"></el-input>
                        <span> 次</span> 
                    </div>
                    <el-button size="small" type="primary" v-if="!warning_threshold.show" @click="edit('warning_threshold')" style="margin-right: 10px;">编辑</el-button>
                    <el-button size="small" type="primary" v-else @click="openDialog(1, warning_threshold)" style="margin-right: 10px;">保存</el-button>
                    <!-- <div class="btn" v-if="!warning_threshold.show" @click="edit('warning_threshold')">编辑</div> -->
                    <!-- <div class="btn" v-else @click="openDialog(1, warning_threshold)">保存</div> -->
                    <div>低于阈值则提醒商户充值</div>
                </div>
            </div>
        </div>
        <div class="recharge">
            <div class="give-title">
                <div class="title1">充值金额</div>
                <div class="title2">说明：自定义配置回收商家的充值金额、到账次数，可动态添加或修改</div>
                <div class="title3" @click="openDialog(6, warning_threshold)">变更记录</div>
            </div>
            <div class="give-num">
                <div>自定义金额</div>
                <el-button style="margin: 0 10px" size="small" type="primary"  @click="addRecharge">+添加</el-button>
                <!-- <div class="btn ml-10" @click="addRecharge">+添加</div> -->
                <div class="ff60">点击“添加”按钮，动态设置</div>
            </div>
            <div class="give-num" v-for="(item,index) in rechargeList" :key="item.id">
                <div>充值金额</div>
                <div class="num">
                    <div v-if="!item.show">{{item.price}} </div>
                    <el-input v-else v-model="item.price" autofocus @blur="validRule('price', 'price', index)" placeholder="请输入充值金额"></el-input>
                    <span> 元</span> 
                </div>
                <div>到账次数</div>
                <div class="num">
                    <div v-if="!item.show">{{item.arrivalTimes}} </div>
                    <el-input v-else v-model="item.arrivalTimes" autofocus @blur="validRule('num', 'arrivalTimes', index)" placeholder="请输入到账次数"></el-input>
                    <span> 次</span> 
                </div>
                <div>赠送次数</div>
                <div class="num">
                    <div v-if="!item.show">{{item.giftTimes}} </div>
                    <el-input v-else v-model="item.giftTimes" autofocus @blur="validRule('num', 'giftTimes', index)" placeholder="请输入赠送次数"></el-input>
                    <span> 次</span>
                </div>
                <!-- <div class="btn">编辑</div> -->
                <el-button size="small" type="primary" v-if="!item.show" @click="edit(item, index)">编辑</el-button>
                <el-button size="small" type="primary" v-else @click="openDialog(2, item, index)">保存</el-button>
                <el-button size="small" type="danger" @click="openDialog(3, item, index)">删除</el-button>
                <!-- <div class="btn" v-if="!item.show" @click="edit(item, index)">编辑</div> -->
                <!-- <div class="btn" v-else @click="openDialog(2, item, index)">保存</div> -->
                <!-- <div class="btn bg-red" @click="openDialog(3, item, index)">删除</div> -->
            </div>
        </div>
        <div class="recharge">
            <div class="give-title">
                <div class="title1">撮合交易扣款次数换算</div>
            </div>
            <div class="give-num">
                <div>撮合交易扣款1次收货次数</div>
                <div style="margin-left:10px;font-weight: bold;">=</div>
                <div class="num">
                    <div v-if="!match_convert_tate.show">{{match_convert_tate.ruleValue}} </div>
                    <el-input v-else v-model="match_convert_tate.ruleValue" autofocus @blur="validRule('', 'match_convert_tate')" placeholder="请输入换算金额"></el-input>
                    <span>元</span> 
                </div>
                <el-button size="small" type="primary" v-if="!match_convert_tate.show" @click="edit('match_convert_tate')">编辑</el-button>
                <el-button size="small" type="primary" v-else @click="openDialog(1, match_convert_tate)">保存</el-button>
                <!-- <div class="btn" v-if="!match_convert_tate.show" @click="edit('match_convert_tate')">编辑</div> -->
                <!-- <div class="btn" v-else @click="openDialog(1, match_convert_tate)">保存</div> -->
            </div>
        </div>
        <el-dialog
            :close-on-click-modal="false"
            :width="type < 4 ? `${700}px` : `${1000}px`"
            :title="title"
            :visible="show"
            @close="close"
            top="15%"
        >
            <div class="img-box">
                <div v-if="type < 4">
                    <div class="mt-50 ff60">说明: {{type == 3?'删除':'编辑'}}数据需要验证身份，请向管理员索要谷歌动态口令进行操作</div>
                    <div class="flex command">
                        <div>动态口令：</div>
                        <el-input v-if="type == 1" v-model="editData.command" placeholder="请输入谷歌动态口令"></el-input>
                        <el-input v-else v-model="rechargeData.command" placeholder="请输入谷歌动态口令"></el-input>
                    </div>
                </div>
                <div v-else>
                    <div v-if="type == 5">
                        <el-table :data="list" border style="width: 100%" id="excelOrder">
                            <el-table-column type="index" label="序号" align="center"/>
                            <el-table-column prop="ruleValueNew" label="变更后数据" align="center"/>
                            <el-table-column prop="ruleValueOld" label="原始数据" align="center"/>
                            <el-table-column prop="createTime" label="变更时间" align="center"/>
                            <el-table-column prop="adminAcc" label="操作账号" align="center"/>
                        </el-table>
                        <div class="orderPagina">
                            <el-pagination
                                background
                                :current-page="pages.pageNum"
                                :page-size="5"
                                layout="total, prev, pager, next,jumper"
                                :total="total"
                                class="el-pagination-c"
                                @current-change="handleCurrentChange"
                            />
                        </div>
                    </div>
                    <div v-if="type == 6">
                        <div class="flex tags">
                            <div class="tag" :class="page.type == '02' ? 'choose' : 'defalut'" @click="chooseTag('02')">修改</div>
                            <div class="tag br" :class="page.type == '03' ? 'choose' : 'defalut'" @click="chooseTag('03')">删除</div>
                            <div class="tag" :class="page.type == '01' ? 'choose' : 'defalut'" @click="chooseTag('01')">新增</div>
                        </div>
                        <el-table :data="list" border style="width: 100%" id="excelOrder">
                            <el-table-column type="index" label="序号" align="center"/>
                            <el-table-column v-if="page.type == '03'" prop="rechargeValueOld" label="删除数据" align="center"/>
                            <el-table-column v-if="page.type == '03'" prop="createTime" label="删除时间" align="center"/>
                            <el-table-column v-if="page.type == '01'" prop="rechargeValueNew" label="新增数据" align="center"/>
                            <el-table-column v-if="page.type == '01'" prop="createTime" label="新增时间" align="center"/>
                            <el-table-column v-if="page.type == '02'" prop="rechargeValueNew" label="变更后数据" align="center"/>
                            <el-table-column v-if="page.type == '02'" prop="rechargeValueOld" label="原始数据" align="center"/>
                            <el-table-column v-if="page.type == '02'" prop="createTime" label="变更时间" align="center"/>
                            <el-table-column prop="adminAcc" label="操作账号" align="center"/>
                        </el-table>
                        <div class="orderPagina">
                            <el-pagination
                                background
                                :current-page="page.pageNum"
                                :page-size="5"
                                layout="total, prev, pager, next,jumper"
                                :total="totals"
                                class="el-pagination-c"
                                @current-change="handleCurrentChange"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer" v-if="type == 1 || type == 2 || type == 3">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="submint">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import _api from '@/utils/request'
import { validateNumber, validateNumberFloat } from '@/utils/validate'
export default({
    data () {
        return {
            // 赠送次数
            init_give: {},
            match_convert_tate: {},
            giveShow: true,
            // 交易扣除
            receiving_consumption: {},
            // 预警阈值
            warning_threshold: {},
            // 变更记录
            pages: {
                pageSize: 5,
                pageNum: 1,
                ruleKey: ''
            },
            total: 0,
            // 套餐变更记录
            page: {
                pageSize: 5,
                pageNum: 1,
                type: '02' // 01:新增02:编辑03:删除
            },
            totals: 0,
            list: [],
            // 套餐初始页面
            rechargeList: [],
            rechargeData: {
                arrivalTimes: '',
                command: '',
                giftTimes: '',
                id: null,
                price: ''
            },
            index: null,
            // 弹窗
            type: null, // 1.编辑体验赠送交易扣费的情况,5.变更记录
            title: '',
            show: false,
            editData: {
                command: '',
                ruleKey: '',
                ruleValue: 0
            }
        }
    },
    created() {
        // 交易扣费,充值金额
        this.getReceivingConfig()
        // 套餐
        this.getRechargeConfigList()
    },
    methods: {
        // 弹窗操作
        openDialog(type, param, index) {
            this.type = type
            if (type == 1) {
                this.title = `编辑-${param.ruleName}`
                this.show = true
                this.editData = {
                    command: '',
                    ruleKey: param.ruleKey,
                    ruleValue: param.ruleValue
                }
            }
            if (type == 2 || type == 3) {
                if (type == 3 && !this.rechargeList[index].id) {
                    this.rechargeList.splice(index, 1)
                } else {
                    this.title = type == 2 ? `编辑数据` : `删除数据` 
                    this.index = index
                    this.show = true
                    this.rechargeData = {
                        start: param.start,
                        arrivalTimes: param.arrivalTimes,
                        command: '',
                        giftTimes: param.giftTimes,
                        id: param.id,
                        price: param.price
                    }
                }
                
            }
            if (type == 5) {
                this.title = `变更记录`
                this.pages.ruleKey = param.ruleKey
                this.getReceivingConfigLog()
                this.show = true
            }
            if (type == 6) {
                this.title = `变更记录`
                this.getRechargeConfigLog()
                this.show = true
            }
        },
        close() {
            // 赠送次数和预警阈值时
            if (this.type == 1) {
                if (this.init_give.ruleKey == this.editData.ruleKey) {
                    this.init_give.ruleValue = this.init_give.start
                    this.init_give.show = false
                }
                if (this.warning_threshold.ruleKey == this.editData.ruleKey) {
                    this.warning_threshold.ruleValue = this.warning_threshold.start
                    this.warning_threshold.show = false
                }
            }
            if (this.type == 2) {
                this.rechargeList[this.index].arrivalTimes = this.rechargeList[this.index].start.arrivalTimes
                this.rechargeList[this.index].giftTimes = this.rechargeList[this.index].start.giftTimes
                this.rechargeList[this.index].price = this.rechargeList[this.index].start.price
                this.rechargeList[this.index].show = false
                this.index = null
            }
            this.reset()
        },
        reset() {
            this.editData = {
                command: '',
                ruleKey: '',
                ruleValue: ''
            }
            this.pages = {
                pageSize: 5,
                pageNum: 1,
                ruleKey: ''
            }
            this.total = 0
            this.page = {
                pageSize: 5,
                pageNum: 1,
                type: '02' // 01:新增02:编辑03:删除
            }
            this.totals = 0
            this.rechargeData = {
                arrivalTimes: 0,
                command: '',
                giftTimes: 0,
                id: null,
                price: 0
            }
            this.type = null
            this.index = null
            this.list = []
            this.show = false
        },
        /********************** 体验赠送 交易扣费 ******************/
        // 获取相应初始数据
        getReceivingConfig() {
            _api.getReceivingConfig().then(res => {
                if (res.code == 1) {
                    res.data.map(item => {
                        item.show = false
                        item.btntxt = '编辑'
                        item.start = item.ruleValue
                        item.rule = false
                        switch(item.ruleKey) {
                            case "INIT_GIVE":
                                this.init_give = item
                                break
                            case "RECEIVING_CONSUMPTION":
                                this.receiving_consumption = item
                                break
                            case "WARNING_THRESHOLD":
                                this.warning_threshold = item
                                break
                            case "MATCH_CONVERT_TATE":
                                this.match_convert_tate = item
                                break
                        }
                    })
                    console.log(this.init_give)
                }
            })
        },
        // 1.失焦编辑置为保存
        edit(name, index) {
            // 重置所有初始状态为置灰
            for (const item of this.rechargeList) {
                item.arrivalTimes = item.start.arrivalTimes
                item.giftTimes = item.start.giftTimes
                item.price = item.start.price
                item.show = false
            }
            this.warning_threshold.show = false
            this.warning_threshold.ruleValue = this.warning_threshold.start
            this.init_give.show = false
            this.init_give.ruleValue = this.init_give.start
            // 根据条件转为编写状态
            if ((index && index != undefined) || index == 0) {
                this.rechargeList[index].show = true
            } else {
                this[name].show = true
            }
            console.log(this.rechargeList[index])
        },
        // 2.编辑赠送次数
        submint() {
            let requestParam = [{
                url: _api.submintGive,
                data: this.editData
            },{
                url: _api.updateRechargeConfig,
                data: this.rechargeData
            }]
            if (this.type == 1) {
                requestParam[0].url(requestParam[0].data).then(res => {
                    this.getReceivingConfig()
                    this.reset()
                })
            }
            if (this.type == 2) {
                if (!requestParam[1].data.id) {
                    delete requestParam[1].data.id
                }
                delete requestParam[1].data.start
                requestParam[1].url(requestParam[1].data).then(res => {
                    this.getRechargeConfigList()
                    this.reset()
                })
            }
            if (this.type == 3) {
                this.removeRechargeConfig()
            }
        },
        // 3.查看记录
        getReceivingConfigLog() {
            _api.getReceivingConfigLog(this.pages).then(res => {
                if (res.code == 1) {
                    console.log(res)
                    this.list = res.data.records
                    this.total = res.data.total
                }
            })
        },
        handleCurrentChange(e) {
            console.log(e)
            if (e) {
                if (this.type == 6) {
                    this.page.pageNum = e
                    this.getRechargeConfigLog()
                } else {
                    this.pages.pageNum = e
                    this.getReceivingConfigLog()
                }
            } else {
                if (this.type == 6) {
                    this.pages.pageNum = 1
                    this.getRechargeConfigLog()
                } else {
                    this.page.pageNum = 1
                    this.getReceivingConfigLog()
                }
            }
        },
        /********************** 套餐 ******************/
        // 套餐相关记录
        chooseTag(e) {
            this.page = {
                pageSize: 5,
                pageNum: 1,
                type: e
            }
            this.getRechargeConfigLog()
        },
        getRechargeConfigLog() {
             _api.getRechargeConfigLog(this.page).then(res => {
                if (res.code == 1) {
                    console.log(res)
                    this.list = res.data.records
                    this.totals = res.data.total
                }
            })
        },
        // 套餐列表
        getRechargeConfigList() {
           _api.getRechargeConfigList().then(res => {
                console.log(res)
                if (res.code == 1) {
                    for (const item of res.data) {
                        item.start = {
                            arrivalTimes: item.arrivalTimes,
                            giftTimes: item.giftTimes,
                            price: item.price
                        }
                        item.show = false
                        item.btntxt = '编辑'
                    }
                   this.rechargeList = res.data
                }
            })
        },
        // 添加
        addRecharge() {
            for (const item of this.rechargeList) {
                item.show = false
                item.arrivalTimes = item.start.arrivalTimes
                item.price = item.start.price
                item.giftTimes = item.start.giftTimes
            }
            const item = [{
                start: {
                    arrivalTimes: 0,
                    giftTimes: 0,
                    price: 0
                },
                arrivalTimes: 0,
                giftTimes: 0,
                price: 0,
                show: true,
                btntxt: '新增'
            }]
            this.rechargeList = [...this.rechargeList, ...item]
        },
        // 删除
        removeRechargeConfig() {
            const data = {
                id: this.rechargeData.id,
                command: this.rechargeData.command
            }
            _api.removeRechargeConfig(data).then(res => {
                this.getRechargeConfigList()
                this.reset()
            })
        },
        /*********************************验证 */
        validRule(type, name, index) {
            // 次数
            if (type == 'num') {
                if ((index && index != undefined) || index == 0) {
                    if (!validateNumber(this.rechargeList[index][name])) {
                        this.rechargeList[index][name] = this.rechargeList[index].start[name]
                    }
                } else {
                    if (!validateNumber(this[name].ruleValue)) {
                        this[name].ruleValue = this[name].start
                    }
                }
            } else {
                console.log(index);
                if((index && index != undefined) || index == 0){
                    if (!validateNumberFloat(this.rechargeList[index][name])) {
                        this.rechargeList[index][name] = this.rechargeList[index].start[name]
                    }
                }else{
                    if (!validateNumberFloat(this[name].ruleValue)) {
                        this[name].ruleValue = this[name].start
                    }
                }
                
            }
        }
    }
})
</script>
<style scoped lang="scss">
.main{
    .give,.transaction,.recharge{
        margin-bottom: 80px;
        .give-title{
            display: flex;
            align-items: center;
            font-size: 14px;
            border-left: 8px solid #3399FF;
            padding: 0 10px;
            box-sizing: border-box;
            .title1{
                font-weight:bold;
                color:#333;
                margin-right: 10px;
            }
            .title2{
                color:#FF3300;
                margin-right: 10px;
            }
            .title3{
                color:#3399FF;
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .give-num{
            display:flex;
            align-items: center;
            margin: 20px 0 0 18px;
            font-size:14px;
            box-sizing: border-box;
            .num{
                display:flex;
                align-items: center;
                margin: 0 30px 0 10px;
                >div{
                    width: 130px;
                    height: 28px;
                    text-align: center;
                    line-height: 28px;
                    background: #f2f2f2;
                }
                >span{
                    font-weight: 600;
                }
                /deep/.el-input{
                    >input{
                        width: 130px;
                        height: 28px;
                        text-align: center;
                    }
                }
            }
            .btn{
                width: 54px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                background: #3399FF;
                color: #fff;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
}
.flex{
    display: flex;
    align-items: center;
}
.ff60{
    color: #FF6600;
}
.bg-red{
    background: #FF3300!important;
}
.ml-10{
    margin-left: 10px;
}
.mt-50{
    margin-top: 50px;
}
.command{
    div{
        margin: 50px 0;
        width: 100px;
    }
   /deep/.el-input{
        flex: 1;
        >input{
            width: 100%;
            height: 28px;
            text-align: left;
        }
    } 
}
.orderPagina{
    display: flex;
    flex-direction: row-reverse;
    margin: 20px 0;
}
.tags{
    width: 210px;
    border: 1px solid rgba(64, 158, 255, 1);
    box-sizing: border-box;
    margin-bottom: 20px;
    .tag{
        width: 70px;
        height: 28px;
        cursor: pointer;
        text-align: center;
        line-height: 28px;
    }
    .br{
        border-right: 1px solid rgba(64, 158, 255, 1);
    }
    .choose{
        background: rgba(64, 158, 255, 1);
        color: #fff
    }
    .defalut{
        background:  #fff;
        color:rgba(64, 158, 255, 1)
    }
}
</style>